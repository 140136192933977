import {
  Box,
  Button, Checkbox, Grid, Typography, makeStyles, useMediaQuery, useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { composedComponent } from 'utils/functions';
import roles from 'screens/User/constants';
import type from 'utils/type';
import styles from './styles';
import Skeleton from './Skeleton';
import { homeMarketingActions } from './reducer';
import saga from './saga';

const useStyles = makeStyles(styles);

function HomeMarketing({
  user,
  isSchedulingEnabled,
  actions,
  loading,
  loadingUserReports,
}) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { type: role } = user.user;
  const hasReports = role === type.ADMINISTRADOR_DE_CONTRATO
                    || role === type.ADMINISTRADOR_PORTAL_SALFA
                    || user?.reports?.length > 0;

  useEffect(() => {
    actions.getInitialState();
  }, []);

  /** @param {'newsletter' | 'scheduling'} key */
  const handleToggleItemEnabled = (key) => (checked) => {
    actions.toggleHomeMarketingItem({ key, value: checked });
  };

  if (loading || loadingUserReports) {
    return (
      <Skeleton />
    );
  }

  if (isMobile) {
    return (
      <>
        {role === roles.ADMINISTRADOR_PORTAL_SALFA && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setIsEditing(!isEditing)}
          >
            Editar
          </Button>
        )}
        <Grid item xs={12} className={classes.cardMobile}>
          <Link className={classes.link} to="/dashboard">
            <Grid container alignItems="flex-end" justifyContent="center" className={classNames(classes.dashboard, classes.card)}>
              <Typography variant="h4" className={classes.text}>
                Salfa Fleet
              </Typography>
            </Grid>
          </Link>
        </Grid>
        {hasReports && (
        <Grid item xs={12} className={classes.cardMobile}>
          <Link className={classes.link} to="/reports">
            <Grid container alignItems="flex-end" justifyContent="center" className={classNames(classes.reports, classes.card)}>
              <Typography variant="h4" className={classes.text}>
                Reportes
              </Typography>
            </Grid>
          </Link>
        </Grid>
        )}
        <Grid item xs={12} className={classes.cardMobile}>
          { isEditing && (
          <div className={classes.checkboxContainer}>
            <Checkbox
              className={classes.checkbox}
              checked={isSchedulingEnabled}
              onClick={(e) => e.stopPropagation()}
              onChange={(e, checked) => handleToggleItemEnabled('scheduling')(checked)}
            />
          </div>
          )}
          <Link className={classNames(classes.link, { [classes.disabled]: !isSchedulingEnabled })} to="/scheduling">
            <Grid container alignItems="flex-end" justifyContent="center" className={classNames(classes.schedulingMobile, classes.card)}>
              <Typography variant="h4" className={classes.text}>
                Agenda tu servicio
              </Typography>
            </Grid>
          </Link>
        </Grid>
      </>
    );
  }
  return (
    <>
      {role === roles.ADMINISTRADOR_PORTAL_SALFA && (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => setIsEditing(!isEditing)}
        >
          Editar
        </Button>
      )}
      <Grid className={classes.container} container direction="column">
        <Box width={hasReports ? '33%' : '45%'} height="100%" margin="0 auto">
          <Link className={classes.link} to="/dashboard">
            <Grid container alignItems="flex-end" justifyContent="center" className={classNames(classes.dashboard, classes.card)}>
              <Typography variant="h4" className={classes.text}>
                Salfa Fleet
              </Typography>
            </Grid>
          </Link>
        </Box>
        {hasReports && (
        <Box width="33%" height="100%" margin="0 auto">
          <Link className={classes.link} to="/reports">
            <Grid container alignItems="flex-end" justifyContent="center" className={classNames(classes.reports, classes.card)}>
              <Typography variant="h4" className={classes.text}>
                Reportes
              </Typography>
            </Grid>
          </Link>
        </Box>
        )}
        <Box flex={1} width={hasReports ? '25%' : '35%'} height="100%" margin="0 auto">
          { isEditing && (
            <div className={classes.checkboxContainer}>
              <Checkbox
                className={classes.checkbox}
                checked={isSchedulingEnabled}
                onClick={(e) => e.stopPropagation()}
                onChange={(e, checked) => handleToggleItemEnabled('scheduling')(checked)}
              />
            </div>
          )}
          <Link className={classNames(classes.link, { [classes.disabled]: !isSchedulingEnabled })} to="/scheduling">
            <Grid container alignItems="flex-end" justifyContent="center" className={classNames(classes.scheduling, classes.card)}>
              <Typography variant="h4" className={classes.text}>
                Agenda tu servicio
              </Typography>
            </Grid>
          </Link>
        </Box>
      </Grid>
    </>
  );
}

export default composedComponent(HomeMarketing, saga, {
  saga: 'sagaHomeMarketing',
  states: [
    'app.user',
    'app.objects.holdings',
    'app.loadingReports',
    'homeMarketing.loading',
    'homeMarketing.isSchedulingEnabled',
    'homeMarketing.isNewsletterEnabled',
  ],
  actions: [homeMarketingActions],
});
